
























import { Component, Prop, Watch, Vue } from 'vue-property-decorator'

// components
import ButtonIconAction from '@/components/_uikit/buttons/ButtonIconAction.vue'
// store
import SystemModule from '@/store/modules/system'

@Component({
  components: {
    ButtonIconAction,
  },
})
export default class Player extends Vue {
  @Prop({ required: true })
  private url!: string

  @Prop({ default: false })
  private border!: boolean

  @Prop({ default: false })
  private deleteBtn!: boolean

  @Prop({ default: false })
  private deleteFileBtn!: boolean

  @Prop({ default: false })
  private download!: boolean

  @Prop({ default: false })
  private playOnly!: boolean

  @Prop({ required: true })
  private audioID!: number

  @Prop({ default: 0 })
  private playerStartTime!: number // Количество секунд от начала

  private get isPlayingGlobal() {
    return SystemModule.isPlaying
  }

  private get isRecordingGlobal() {
    return SystemModule.isRecording
  }

  private get audioList() {
    return SystemModule.audioList
  }

  private isPlay = false
  private player: HTMLAudioElement | null = null

  private mounted() {
    if (this.$refs.player) {

      this.player = ((this.$refs.player as Vue).$el as HTMLElement).querySelector<HTMLAudioElement>('audio')
      if (this.player) {
        if (this.audioList[this.audioID] !== undefined) {
          this.player.currentTime = this.audioList[this.audioID]
        } else {
          this.player.currentTime = this.playerStartTime
          SystemModule.setAudioList({
            currentTime: 0,
            id: this.audioID,
          })
        }
        this.player.addEventListener('play', this.playRecord)
        this.player.addEventListener('pause', this.stopRecord)
        this.player.addEventListener('ended', this.stopRecord)
        this.player.addEventListener('timeupdate', (e) => this.updatePlayerTimeline(e))
      }
    }
  }

  private beforeDestroyed() {
    if (this.player) {
      this.player.removeEventListener('play', this.playRecord)
      this.player.removeEventListener('pause', this.stopRecord)
      this.player.removeEventListener('ended', this.stopRecord)
    }
  }

  private playRecord() {
    SystemModule.setIsPlaying(this.audioID)
    this.isPlay = true
  }

  private stopRecord() {
    SystemModule.setIsPlaying(null)
    this.isPlay = false
  }

  private updatePlayerTimeline(event: Event) {
    SystemModule.setAudioList({
      currentTime: (event.target as any).currentTime,
      id: this.audioID,
    })
  }

  @Watch('isPlayingGlobal')
  private watchIsPlaying(value: number | null) {
    if (value !== null && this.audioID === SystemModule.isPlaying) {
      this.player?.play()
    } else {
      this.player?.pause()
    }
  }
}
